<div class="content">
  <app-coming-soon title="Dashboard"></app-coming-soon>
</div>

<div class="content" *ngIf="false">
  <header class="page-header mb-3 mb-md-4">
    <div class="d-flex align-items-center flex-wrap">
      <div class="mr-4">
        <h1>Your Afiliate Activity</h1>
      </div>
      <div class="filter no-border m-0 p-0">
        <ng-select [items]="SelectOption" placeholder="Select" class="expanding-dropdown-panel" appendTo="body">
        </ng-select>
      </div>
    </div>
  </header>
  <section class="page-content container-fluid">
    <ng-template ngxPermissionsOnly="Admin">
      <div class="clearfix">
        <div class="form-row mb-3 mb-md-4">
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">New accounts to confirm</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">5 new</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">111Impressions</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">€ 2,000</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Clicks</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">20,000</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Transactions</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">12,946</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Conversion Rate</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">400</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Commission</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">25%</h2>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template ngxPermissionsOnly="Publisher">
      <div class="clearfix">
        <div class="form-row mb-3 mb-md-4">
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Confirmed offers</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">5 new</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Payout</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">€ 2,000</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Impressions</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">20,000</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Clicks</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">12,946</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Transactions</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">400</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Conversion Rate</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">25%</h2>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template ngxPermissionsOnly="Advertiser">
      <div class="clearfix">
        <div class="form-row mb-3 mb-md-4">
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">New Publisher requests</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">5 new</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Due Invoices</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">€ 2,000</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Clicks</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">20,000</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Transactions</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">12,946</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Conversion Rate</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">400</h2>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-2">
            <div class="card bg-primary card-body text-nowrap">
              <h4 class="text-white font-cabin">Invoice</h4>
              <h2 class="font-size-45 mb-0 font-cabin text-white text-right mt-4">25%</h2>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="card">
      <div class="card-body">
        <div class="chart-filter d-flex flex-wrap mb-4">
          <ng-select
            bindValue="id"
            bindLabel="name"
            [items]="label1Options"
            [(ngModel)]="label1SelectedOptions"
            (change)="changeGraphData1($event)"
            placeholder="Select"
            class="mr-4 red expanding-dropdown-panel"
            appendTo="body"
          >
          </ng-select>
          <ng-select
            [(ngModel)]="label2SelectedOptions"
            bindValue="id"
            bindLabel="name"
            [items]="label2Options"
            placeholder="Select"
            class="blue expanding-dropdown-panel"
            (change)="changeGraphData2($event)"
            appendTo="body"
          >
          </ng-select>
        </div>
        <div id="PerformanceChartLegend" class="pie-chart-legend"></div>
        <div class="canvas-holder flex dashboard-graph">
          <canvas id="PerformanceChart" height="550px" width="100%"></canvas>
        </div>
      </div>
    </div>
  </section>
</div>
